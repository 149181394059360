// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-article-tsx": () => import("./../../../src/components/article.tsx" /* webpackChunkName: "component---src-components-article-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cloud-tsx": () => import("./../../../src/pages/cloud.tsx" /* webpackChunkName: "component---src-pages-cloud-tsx" */),
  "component---src-pages-company-news-tsx": () => import("./../../../src/pages/company-news.tsx" /* webpackChunkName: "component---src-pages-company-news-tsx" */),
  "component---src-pages-data-tsx": () => import("./../../../src/pages/data.tsx" /* webpackChunkName: "component---src-pages-data-tsx" */),
  "component---src-pages-developer-tsx": () => import("./../../../src/pages/developer.tsx" /* webpackChunkName: "component---src-pages-developer-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internet-of-things-tsx": () => import("./../../../src/pages/internet-of-things.tsx" /* webpackChunkName: "component---src-pages-internet-of-things-tsx" */),
  "component---src-pages-natural-language-processing-tsx": () => import("./../../../src/pages/natural-language-processing.tsx" /* webpackChunkName: "component---src-pages-natural-language-processing-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-results-tsx": () => import("./../../../src/pages/results.tsx" /* webpackChunkName: "component---src-pages-results-tsx" */)
}

