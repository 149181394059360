/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react";
import { PreviewStoreProvider } from "gatsby-source-prismic";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "material-design-iconic-font/dist/css/material-design-iconic-font.min.css";
import "./src/_js/navScroller.js";

// wrapping our gatsby app in a higher order component for prismic preview functionality
export const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider>{element}</PreviewStoreProvider>
);
